import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../../components/Layout"
import SEO from "../../components/seo"
import { graphql } from "gatsby"
import Loader from "react-loader-spinner"
import "./../../styles/dark-mode.scss"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import SuccessMessage from "../../components/success"

export default ({
  data: {
    pageInfo: {
      mainTitle,
      mainCaption,
      mainContent,
      sidebarHeader,
      sidebarContact,
      sidebarContactLink,
      sidebarAddress,
      successThankyou,
      successMessage,
      successCTA,
      successLabel,
      successLink,
    },
  },
}) => {
  const [loading, isLoading] = useState(true)

  useEffect(() => {
    let id = "marketo"
    const existingScript = document.getElementById(id)

    const loadForm = () => {
      let MktoForms2 = window.MktoForms2 || {}
      MktoForms2.loadForm(
        "//app-sj21.marketo.com",
        "001-VWQ-123",
        1098,
        form => {
          form.onSuccess(function(values, followUpUrl) {
            console.log("onSuccess")
            document.querySelector(".formSubmit").classList.add("hide")
            document.querySelector(".success").classList.remove("hide")
            return false
          })
        }
      )
      isLoading(false)
    }

    if (!existingScript) {
      const script = document.createElement("script")
      script.src = "//app-sj21.marketo.com/js/forms2/js/forms2.min.js"
      script.id = id
      document.body.appendChild(script)
      script.onload = () => loadForm()
    } else {
      loadForm()
    }
  }, [])

  return (
    <Layout
      fluid={true}
      pageInfo={{ pageName: "First Responders Submit A Story" }}
    >
      <SEO
        title="First Responders Submit A Story"
        keywords={[`story`, `first responders`]}
      />
      <Container id="dark-mode" className="main-section" fluid>
        <SuccessMessage
          successThankyou={successThankyou}
          successMessage={successMessage}
          successCTA={successCTA}
          successLabel={successLabel}
          successLink={successLink}
        />
        <Container className="formSubmit">
          <Row>
            <Col className="info-section" md={8}>
              <h1>{mainTitle}</h1>
              <h2>{mainCaption}</h2>
              <p>{mainContent}</p>
              {loading && (
                <Loader
                  className={"d-flex justify-content-center"}
                  type="ThreeDots"
                  color="#941221"
                  height={50}
                  width={50}
                  visible={loading}
                />
              )}
              <form id="mktoForm_1098"></form>
            </Col>
            <Col
              className="contact-section submit-story-contact-section"
              md={4}
            >
              <h4>{sidebarHeader}</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: `<p>${sidebarContact}<a class="email-link" href="mailto:${sidebarContactLink}">${sidebarContactLink}</a></p>`,
                }}
              ></div>
              <div
                dangerouslySetInnerHTML={{ __html: `<p>${sidebarAddress}</p>` }}
              ></div>
            </Col>
          </Row>
        </Container>
      </Container>
    </Layout>
  )
}
export const SuccessQuery = graphql`
  query FPSubmitStoryQuery {
    pageInfo: pagesYaml(slug: { eq: "fp-submit-story" }) {
      mainTitle
      mainCaption
      mainContent
      sidebarHeader
      sidebarContact
      sidebarContactLink
      sidebarAddress
      successThankyou
      successCTA
      successMessage
      successLabel
      successLink
    }
  }
`
